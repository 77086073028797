.gm-style .gm-style-iw-c {
	padding: 0;
	border-radius: 0;
}

.gm-style .gm-style-iw-d {
	overflow: hidden!important;
}

.gm-style .gm-style-iw-d > div {
	width: 100%;
}